

/* MODAL */
/* Background effect. It covers the entire site */
.modal {
    position: absolute;
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Overlay effect: translucent background: black w/ partial opacity */
    z-index: 100; /* Overlay effect: positioned over other containers */
    width: 100%; /* Full width */
    height: fit-content; /* Full height */
    top: 0;
    left: 0;
    overflow: auto; /* Enable scroll if needed */
    padding-top: 20px; 
}
/* Modal content */
.modal-content {
    background-color: white;
    width: 95%; /* Width in proportion to its parent container*/
    min-height: fit-content; /* Height in proportion to its parent container */
    margin: auto; /* Auto margin according to the element width */
    padding: 10px;
    border: 1px solid black;
    border-radius: 20px; /* Optional. Rounds container corners */
}
/* Close button */
.close {
   color: #aaaaaa;
   float: right; /* Positioned to the right of the parent container whichever size it is */
   font-size: 25px;
   font-weight: bold;
}
.close:hover, 
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}