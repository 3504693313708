
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  
  @keyframes logoAnimation {
    0%{
      transform: scale(1) rotate(0deg);
    }
    25% {
      transform: scale(1.2) rotate(45deg);
    }
    50% {
      transform: scale(1.5) rotate(-45deg);
    }
    75% {
      transform: scale(1.7) rotate(60deg) ;
    }
    100%{
      transform: scale(1) rotate(360deg);
    }
  }
  
  
  .logo {
    width: 100px; /* Initial width of your logo */
    /* height: 100px; Initial height of your logo */
    /* background-color: #3498db; Adjust the background color */
    animation: logoAnimation 2s infinite; /* Adjust the duration */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff; /* Adjust the text color */
    font-size: 24px; /* Adjust the font size */
  }
  