

  #drone{
    animation: movex 10s ease-in-out infinite alternate-reverse;
    transform-origin: center;
    transform-box: fill-box;
}

#wing1, #wing5, #wing3, #wing7,#wing2, #wing4, #wing8, #wing6 {
    transform-origin:center;
    transform-box: fill-box;
    animation: girlshands 1ms ease-in-out infinite alternate-reverse;
}

#messageback, #backmessageback {
    animation: messages 5s ease-in-out infinite alternate-reverse;
}

#fronthand {
    animation: girlshand 4s ease-in-out infinite alternate-reverse;
    transform-origin: center;
    transform-box: fill-box;
}

#hair, #hairboy {
    animation: hairs 4s ease-in-out infinite alternate-reverse;
    transform-origin: center;
    transform-box: fill-box;
}

#frame1ladki {
    animation: frame 6s ease-in-out infinite alternate-reverse;
}

#orange2 {
    animation: oranges 4s ease-in-out infinite alternate-reverse;
}

#gimpble {
    animation: orangesball 4s ease-in-out infinite alternate-reverse;
}

#seconddot {
    animation: dot1 5s ease-in-out infinite forwards;
}

#firstdot {
    animation: dot2 5s ease-in-out infinite forwards;
}

#thirddot {
    animation: dot3 5s ease-in-out infinite forwards;
}

@keyframes movex {
    0% {
        transform: translate(0px, 0px) rotateZ(0deg) scaleX(1);
    }
    100% {
        transform: translate(10px, -9px) rotateZ(5deg) scaleX(1.1);
    }
}

@keyframes girlshands {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes girlshand {
    0% {
        transform: rotateZ(0);
    }
    100% {
        transform: rotateZ(3deg);
    }
}

@keyframes hairs {
    0% {
        transform: translate(0, 0) rotateZ(0);
    }
    100% {
        transform: translate(3px, 4px) rotateZ(15deg);
    }
}

@keyframes oranges {
    0% {
        transform: translateX(0) rotateZ(0);
    }
    100% {
        transform: translateX(100px) rotateZ(20deg);
    }
}

@keyframes dot1 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-20px);
    }
}

@keyframes dot2 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-20px);
    }
}

@keyframes dot3 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-20px);
    }
}

@keyframes orangesball {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(40px);
    }
}

@keyframes messages {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(30px);
    }
}

@keyframes frame {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(45px);
    }
}
