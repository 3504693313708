@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
* {
  ::-webkit-scrollbar {
    width: 0px; /* You can adjust this value as needed */
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

@layer components {
  input[type="text"].focus\:ring-0:focus {
    outline: none;
    /* Add other styles as needed */
  }
}
/* tailwind.css */

@layer components {
  input[type="text"].focus\:ring-0:focus {
    outline: none;
    /* Add other styles as needed */
  }
}

body {
  /* background: #549eff;

  background: linear-gradient(270deg, #1c7dfb 45vh, hsla(0, 0%, 100%, 1) 30vh);

  background: -moz-linear-gradient(
    270deg,
    #1c7dfb 45vh,
    hsla(0, 0%, 100%, 1) 30vh
  );

  background: -webkit-linear-gradient(
    270deg,
    #1c7dfb 45vh,
    hsla(0, 0%, 100%, 1) 30vh
  ); */

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#547AF7", endColorstr="#FFFFFF", GradientType=1 );

  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Open Sans", sans-serif;
  /* font-family: "Orbitron", sans-serif;
  font-family: "Roboto", sans-serif; */
  font-weight: bold;
}

input:focus-visible{
 
}

[type='text']:focus{
  outline: none;
  border: 2px solid #3F8786 !important;
  border-radius: 12px;
}

input:focus{
  outline: none !important;
  border: 2px solid #3F8786 !important;
  border-radius: 12px;
}

select:focus{
  outline: none !important;
  border: 2px solid #3F8786 !important;
  /* border-radius: 12px; */
}


input:active{
  outline: none;
  /* border: 2px solid #3F8786 !important; */
}

.row_shadow{
  box-shadow: 10px 10px 50px 6px rgba(0,0,0,0.1);
}

/* react modal css */
.ReactModal__Overlay--after-open{
  z-index: 20;
  background-color: rgba(183, 175, 175, 0.75) !important;
}


.PhoneInputInput{
  border-radius: 0.375rem;
  border-color: #dde3e8;
  padding-top: 0.75rem ;
  padding-bottom: 0.75rem ;
  padding-left: 1rem ;
  padding-right: 1rem ;
}

@media screen and (max-width: 600px) {

  .ReactModal__Content--after-open{
    /* inset: 10px !important; */
  }

}

@media screen and (max-width: 450px) {

  #qr{
    /* margin-left: -17px !important; */
  }
}


[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}